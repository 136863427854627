import React, { useState } from "react";
import css from 'styled-jsx/css';
import { Button, Form, FieldGroup, AlphanumericInput } from "@lmig/lmds-react";
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import Router from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import QuoteProducts from "./QuoteProducts";

const Hero = () => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      object().shape({
        query: string()
          .required('Must enter a valid ZIP Code')
          .matches(/^\d{5}$/, 'Must enter a valid ZIP Code'),
      }),
    ),
  });
  const [curVal, setCurVal] = useState(undefined);

  const submitSearch = () => {
    const passedQuery = { query: curVal, location: true, radius: '25' };
    const params = new URLSearchParams(window?.location?.search);
    const passedQueryParams = new URLSearchParams(passedQuery);

    passedQueryParams.forEach((value, name) => {
      params.set(name, value);
    });

    Router.push(
      {
        pathname: '/insurance-agent',
        passedQuery,
      },
      `/insurance-agent?${params.toString()}`,
    );
  };

  const onAlphaChange = (curSearch) => {
    setValue('query', curSearch);
    setCurVal(curSearch);
    clearErrors();
  };

  const validationMessages = Object.entries(errors).map(([, { message }]) => ({ type: 'error', text: message }));

  const { className: headingClass, styles: headingStyles } = css.resolve`
    @import './global-styles/tokens';

    .lm-Heading {
      color: #1a1446;
      margin: 0 auto 1rem;
      font-size: 2.5rem;
      text-align: center;
      @media screen and (min-width: $lm-breakpoint-md-min) {
        max-width: unset;
        font-size: 3rem;
        margin: 0 0 1rem;

        @media screen and (min-width: $lm-breakpoint-lg-min) {
          text-align: left;
        }
      }
    }
  `;

  return (
    <div id="heroWrapper">
      <div className="textSection">
        <Heading type="h2-light" tag="h1" className={headingClass} mobileHeadingCentered>
          Expert insurance advice from<b style={{ fontWeight: 700 }}> an agent near you</b>
        </Heading>
        <h2 className="heroSubtitle">Find a <b style={{ fontWeight: 700 }}>local insurance agent</b> in your community</h2>
        <Form id="find-an-agent-search" data-testid="search-find-an-agent-form" onSubmit={handleSubmit(submitSearch)}>
          <FieldGroup messages={validationMessages} className="formFieldGroup">
            <div className="formContent">
              <div data-theme="evolved" className="formAction">
                <AlphanumericInput
                  labelVisual="ZIP Code"
                  onChange={(e) => onAlphaChange(e.target.value)}
                  className="searchBar"
                  value={curVal}
                  data-testid="find-agent-search-bar"
                  id="find-agent-search-bar"
                  filter={/^[0-9]/i}
                  type="tel"
                  mask="zipCode"
                  maxLength={5}
                />
              </div>
              <div data-theme="evolved" className="formAction">
                <Button size="small" className="searchButtonHead" id="find-an-agent-search-button" type="submit" data-testid="search-agent-submit" variant="primary">Find an agent</Button>
              </div>
            </div>
          </FieldGroup>
        </Form>
      </div>
      <div className="orSection">- or -</div>
      <div className="quoteSection">
        <QuoteProducts useDefaultLobs hasShowMore />
      </div>
      <style global jsx>{`@import './HeroAB.scoped.scss';`}</style>
      {headingStyles}
    </div>
  );
};

export default Hero;
