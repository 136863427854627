/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "@lmig/lmds-react";
import { sortWeightedLottery } from "@lmig/dotcom-aspect-comparion-helpers/sortWeightedLottery";
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import PhoneNumber from '@lmig/dotcom-aspect-components/Utility/PhoneNumber';
import WrapperSizeable from "../Utility/WrapperSizeable";
import Rating from "../Rating";
import { Maps } from '../Google/DynamicExports';
import styles from "./AgentsNearbyMap.module.scss";

const AgentsNearbyMap = ({
  heading,
  entity = [],
  city = "",
  state = "",
}) => {
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const filteredAgents = entity.filter((agent) => agent.isRemote === false);
    const sortedAgents = sortWeightedLottery(filteredAgents);
    const slicedAgents = sortedAgents.slice(0, 3);
    setSelectedAgents(slicedAgents);

    const officesToAdd = [];
    slicedAgents.forEach(({ offices }) => {
      offices.forEach((office) => {
        if (office.officeType === "FIELD" && officesToAdd.findIndex(office_ => JSON.stringify(office_.address) === JSON.stringify(office.address)) === -1) {
          const toAdd = {
            name: `${office.address.city}, ${office.address.state.code}`,
            lat: office?.location?.coordinates[1],
            lng: office?.location?.coordinates[0],
            address: office?.address,
            alias: office?.aliasPath,
            phone: office.phones?.primary?.number,
          };
          officesToAdd.push(toAdd);
        }
      });
    });
    setMarkers(officesToAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    {selectedAgents.length === 3 && <WrapperSizeable backgroundColor="lightLibertyTeal">
      <div className={styles.headingContainer}>{heading}</div>
      <div className={styles.agentsNearbyMap}>
        <div className={`${styles.agentsContainer}`}>
          {selectedAgents.map((agent) => {
            let imgSrc = 'comparion/entity/agent/blank-profile-photo.jpg';
            let imgAlt = 'Placeholder Image, Insurance Agent | Comparion';
            const office = agent?.offices.filter((office_) => office_.officeType === "FIELD");

            if (agent?.image) {
              imgSrc = agent.image.src || imgSrc;
              imgAlt = agent.image.alt || imgAlt;
            }

            return (
              <div key={agent.displayName} className={`${styles.agentCard}`}>
                <div className={`${styles.agentImage}`}>
                  <Image type="circle" imageDomain="IMAGE_EMS" deferLoad={false}>
                    <img src={imgSrc} alt={imgAlt} width={500} height={500} />
                  </Image>
                </div>
                <div className={`${styles.blueLine}`} />
                <div className={`${styles.infoSection}`}>
                  <Link href={agent.aliasPath} className={`${styles.agentLink}`} variant="standalone" caretPosition="none">{agent.displayName}</Link>
                  <div className={`${styles.officeInfo}`}>{office[0].address.street}</div>
                  {office[0]?.address.additionalStreetInfo?.length > 0 && <div className={`${styles.officeInfo}`}>{office[0]?.address.additionalStreetInfo}</div>}
                  <div className={`${styles.officeInfo}`}>{office[0].address.city}, {office[0].address.state.code} {office[0].address.zip}</div>
                  <PhoneNumber tel={agent?.phones?.primary?.number}>
                    <Link
                      variant="standalone"
                      caretPosition="none"
                      aria-label="Contact us"
                    />
                  </PhoneNumber>
                  <Rating rating={agent?.rating} count={agent?.reviewCount} />
                </div>
              </div>);
          })}
          <Link variant="standalone" className={`${styles.seeAllLink}`} href={`/insurance-agent?query=${city}%2C+${state}&radius=5&location=true`}>See all agents</Link>
        </div>
        <div className={`${styles.mapWrapper}`}>
          <Maps height="90%" markers={markers} />
        </div>
      </div>
    </WrapperSizeable>}
  </>);
};

AgentsNearbyMap.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  entity: PropTypes.array,
  city: PropTypes.string,
  state: PropTypes.string,
};

export default AgentsNearbyMap;