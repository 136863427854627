import React from "react";
import PropTypes from "prop-types";
import { Heading, Link, IconLinkCaretRight } from '@lmig/lmds-react';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import TextBody from '@lmig/dotcom-aspect-components/Utility/TextBody';
import LinkEnvironmentalize from "./LinkEnvironmentalize";

const ResourceCard = ({
  title = '',
  text,
  linkHref,
  agentImageSrc,
  agentImageAlt
}) => (<>
  <div id="relatedCard">
    <LinkEnvironmentalize domain="COMPARION">
      <Link href={linkHref} variant="standalone" className="titleSection" caretPosition="none">
        <div className="titleWording">{title}
          <IconLinkCaretRight />
        </div>
      </Link>
    </LinkEnvironmentalize>
    <div className="bodySection">
      <Image imageDomain="IMAGE" type="circle" preload className="imageContainer">
        <img
          className="resourceImage"
          src={agentImageSrc}
          alt={agentImageAlt}
          height="120px"
          width="120px"
          itemProp="image"
        />
      </Image>
      <div className="text">{text}</div>
    </div>
  </div>
  <style global jsx>{`@import './RelatedResources.scoped.scss';`}</style>
</>);

ResourceCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  linkHref: PropTypes.string.isRequired,
  agentImageSrc: PropTypes.string.isRequired,
  agentImageAlt: PropTypes.string.isRequired,
};

const RelatedResources = ({
  resources,
  title = 'Related content',
  subHeading,
  relatedContentAuthors
}) => (<>
  <div id={subHeading && "headingTextWrapper"}>
    {subHeading ? <Heading tag="h1" type="h2-bold" className="titleHeading">{title}</Heading> : <Heading tag="h2" type="h3-light">{title}</Heading>}
    {subHeading && subHeading !== ' ' && <TextBody className="subHeading">{subHeading}</TextBody>}
  </div>
  <div id={subHeading && "resourcesWrapper"}>
    <div className={subHeading && "resourcesGrid"}>
      {resources.map((resource) => {
        const agent = relatedContentAuthors.find(({ alias }) => alias === resource.agent);

        return agent ? <ResourceCard {...resource} key={resource.title} agentImageAlt={agent.image.alt} agentImageSrc={agent.image.src} /> : null;
      })}
    </div>
  </div>
  <style global jsx>{`
    @import './global-styles/tokens';
    #headingTextWrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .lm-Body.subHeading{
        margin-bottom: 2.5rem;
        font-size: 1.2rem;
      }
      .lm-Heading.titleHeading{
        max-width: unset;
      }
    }

    #resourcesWrapper{
      display: flex;
      justify-content: center;
      margin-bottom: 2.5rem;
      .resourcesGrid{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1.5rem;
        @media screen and (min-width: $lm-breakpoint-lg-min) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  `}</style>
</>);

RelatedResources.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      agent: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      linkHref: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { ResourceCard };
export default RelatedResources;