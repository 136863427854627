import React from 'react';
import { IconCaretRight } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import LmdsIcon from '@lmig/dotcom-aspect-components/Icon/LmdsIcons';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import TextBody from '@lmig/dotcom-aspect-components/Utility/TextBody';
import useWindowWidth from '@lmig/dotcom-aspect-comparion-hooks/useWindowWidth';
import LinkEnvironmentalize from './LinkEnvironmentalize';

const { className: headingClass, styles: headingStyles } = css.resolve`
  @import './global-styles/tokens';
  .lm-Heading{
    color: #1A1446;
    margin: 4rem 0 2rem 0;
    @media screen and (min-width: $lm-breakpoint-sm-min) {
      margin: 5rem 0 3rem;
    }
  }
`;

const ProductCard = ({
  headline,
  text,
  coverages,
  imageSrc = '',
  imageAlt = ''
}) => (
  <div id="productCard">
    <div className='floatingCard'>
      <div className='cardContent'>
        <Heading marginTop="none" align="center" tag="h3" type="h3" className="cardHeading">
          {headline}
        </Heading>
        {text && (<TextBody className="description">{text}</TextBody>)}
        {imageSrc ? (<Image imageDomain="IMAGE" deferLoad>
          <img
            className="productImage"
            src={imageSrc}
            alt={imageAlt}
            width="250"
            height="250"
          />
        </Image>) : <div className="noImageSpacing" />}
        <div className='coverageLabel'>Coverage</div>
        <div className='coverages'>
          {coverages.map(
            ({
              icon = undefined,
              title = undefined,
              href = undefined,
              linkDomain = 'LM_COM',
            }) => {

              if (href) {
                return (<div className="individualCoverage" key={title}>
                  <LinkEnvironmentalize domain={linkDomain}>
                    <a href={href} className='productLink'>
                      <div className='iconAndText'>
                        <LmdsIcon icon={icon} size="24" color="blue" className="clickableIcon" />
                        <h4 className='coverageTitle coverageTitleHeader'>{title}</h4>
                      </div>
                      <IconCaretRight size="16" className="caret" />
                    </a>
                  </LinkEnvironmentalize>
                </div>);
              }

              return (<div className="individualCoverageNoLink" key={title}>
                <TextBody className="label">
                  <LmdsIcon icon={icon} size="24" color="blue" className="nonClickableIcon" />
                  <h4 className='coverageTitle coverageTitleHeader'>{title}</h4>
                </TextBody>
              </div>);
            }
          )}
        </div>
      </div>
    </div>
    <style global jsx>{`@import './InsuranceProducts.scoped.scss';`}</style>
  </div >
);

ProductCard.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  coverages: PropTypes.array.isRequired,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
};

const InsuranceProducts = ({
  productTypes,
  city,
  state,
  isRemote = false,
  showHeading = true
}) => {
  const { isMobile } = useWindowWidth({ mobileWidth: 425 });

  return (
    <div className='productsWrapper'>
      {!!showHeading &&
        <Heading marginBottom="none" marginTop="none" align="center" tag="h2" type={isMobile ? "h3-bold" : "h2-bold"} className={headingClass}>
          {isRemote ? "Insurance options" : `Insurance in ${city}, ${state}`}
        </Heading>
      }
      <div className='container'>
        {productTypes.map(({ title, description, coverages, imageSrc, imageAlt }) => (
          <ProductCard headline={title} text={description} coverages={coverages} key={title} imageSrc={imageSrc} imageAlt={imageAlt} />
        ))}
      </div>
      {headingStyles}
      <style jsx>{`
        @import './global-styles/tokens';
        .productsWrapper{
          margin-bottom: 4rem;
          display: flex;
          flex-flow: column;
          align-items: center;
          @media screen and (min-width: $lm-breakpoint-sm-min) {
            margin-bottom: 4rem;
          }
        }
        .container{
          margin: 1rem 0 0.5rem;
          display: flex;
          flex-flow: column;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          @media screen and (min-width: $lm-breakpoint-lg-min) {
            flex-flow: row;
            align-items: unset;
            column-gap: 1rem;
          }
        }
      `}</style>
    </div>
  );
};

InsuranceProducts.propTypes = {
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imageSrc: PropTypes.string,
      imageAlt: PropTypes.string,
      coverages: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        href: PropTypes.string,
        linkDomain: PropTypes.string,
      }))
    })
  ).isRequired,
  isRemote: PropTypes.bool,
  showHeading: PropTypes.bool,
  state: PropTypes.string,
  city: PropTypes.string,
};

export default InsuranceProducts;
