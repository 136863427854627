import React from 'react';
import { Link, IconButton } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import LmdsIcon from '@lmig/dotcom-aspect-components/Icon/LmdsIcons';
import LinkEnvironmentalize from './LinkEnvironmentalize';

const InsuranceProductsList = ({
  productTypes,
  linkText,
  linkHref,
  heading,
}) => (<div className='productsListWrapper'>
  <div>{heading}</div>
  <div className='productsWrapper'>
    {productTypes.map(({ icon, label, href }) => (
      <div key={label} data-theme="evolved">
        <IconButton hasOutline href={href} data-testid={label}>
          <LmdsIcon icon={icon} size={{ base: '32', md: '48' }} label={label} />
        </IconButton>
      </div>))}
  </div>
  <LinkEnvironmentalize domain="COMPARION">
    <Link href={linkHref} variant='standalone'>
      {linkText}
    </Link>
  </LinkEnvironmentalize>
  <style jsx>{`
    @import './global-styles/tokens';
    .productsListWrapper{
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 1rem;
    }
    .productsWrapper{
      display: flex;
      max-width: 16.5rem;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 2rem;
      flex-flow: row wrap;
      row-gap: 2rem;
      column-gap: 2rem;
      @media screen and (min-width: $lm-breakpoint-sm-min) {
        row-gap: 4rem;
        column-gap: 4rem;
        margin-bottom: 2rem;
        max-width: 26rem;
      }
      @media screen and (min-width: 64rem) {
        margin-bottom: 3rem;
        max-width: 56rem;
      }
    }
  `}</style>
</div>);

InsuranceProductsList.propTypes = {
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      href: PropTypes.string,
    })
  ).isRequired,
  linkText: PropTypes.string.isRequired,
  linkHref: PropTypes.string.isRequired,
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default InsuranceProductsList;