/* eslint-disable no-plusplus */
/* eslint-disable complexity */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, IconButton, IconCaretCircleLeft, IconCaretCircleRight, IconRatingsStarFilled, IconRatingsStarHalf, IconRatingsStarUnfilled } from "@lmig/lmds-react";
import useWindowWidth from '@lmig/dotcom-aspect-comparion-hooks/useWindowWidth';
import RatingStars from "../RatingStars";
import WrapperSizeable from "../Utility/WrapperSizeable";

const ReviewsByLocation = ({
  heading,
  entity = [],
}) => {
  const { isMobile: isOneReview } = useWindowWidth({ mobileWidth: 1180 });
  const [displayButtons, setDisplayButtons] = useState(true);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [paginatedReviews, setPaginatedReviews] = useState([]);
  const [numPages, setNumPages] = useState([]);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    const numOfOffices = entity.length > 3 ? 3 : entity.length;
    // eslint-disable-next-line no-nested-ternary
    const maxReviewLength = numOfOffices === 3 ? 2 : (numOfOffices === 2 ? 3 : 6);
    const reviewsToAdd = [];

    for (let x = 0; x < numOfOffices; x++) {
      const reviews = entity[x]?.reviews;
      reviews?.sort((a, b) => b.rating - a.rating);

      for (let i = 0; i < reviews?.length; i++) {
        const review = reviews[i];

        if (review?.content !== "" && (review.rating === 5 || review.rating === 4) && review?.content.length > 75) {
          const reviewToAdd = {
            authorName: review.authorName,
            content: review.content,
            rating: review.rating,
            entityPath: entity[x].aliasPath,
            entityName: `${entity[x]?.address?.city}, ${entity[x]?.address?.state?.code}`
          };
          reviewsToAdd.push(reviewToAdd);

          if (reviewsToAdd.length === (maxReviewLength * (x + 1))) {
            setSelectedReviews(reviewsToAdd);
            break;
          }
        }
      }

      if (reviewsToAdd.length < 6 && reviewsToAdd.length >= 3) {
        setSelectedReviews(reviewsToAdd);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configurePages = (lengthOfTabs) => {
    const tempPages = [];

    for (let i = 0; i < lengthOfTabs; i++) {
      tempPages.push(i);
    }

    setNumPages(tempPages);
  };

  useEffect(() => {
    if (isOneReview) {
      setPaginatedReviews(selectedReviews.slice(0, 1));

      if (selectedReviews.length > 1) {
        configurePages(selectedReviews.length);
        setDisplayButtons(true);
      }
    } else {
      setPaginatedReviews(selectedReviews.slice(0, 3));

      if (selectedReviews.length > 3) {
        configurePages(2);
        setDisplayButtons(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOneReview, selectedReviews]);

  const paginate = (direction) => {
    if (isOneReview) {
      if (direction === "right") {
        if (pageNum === selectedReviews.length - 1) {
          setPaginatedReviews(selectedReviews.slice(0, 1));
          setPageNum(0);
        } else if (pageNum === 0) {
          setPaginatedReviews(selectedReviews.slice(1, 2));
          setPageNum(1);
        } else {
          setPaginatedReviews(selectedReviews.slice(pageNum + 1, pageNum + 2));
          setPageNum(pageNum + 1);
        }
      } else if (direction === "left") {
        if (pageNum === 0) {
          setPaginatedReviews(selectedReviews.slice(5, 6));
          setPageNum(5);
        } else {
          setPaginatedReviews(selectedReviews.slice(pageNum - 1, pageNum));
          setPageNum(pageNum - 1);
        }
      }
    } else if (direction === "right") {
      if (pageNum === 1) {
        setPaginatedReviews(selectedReviews.slice(0, 3));
        setPageNum(0);
      } else {
        setPaginatedReviews(selectedReviews.slice(3, 6));
        setPageNum(1);
      }
    } else if (direction === "left") {
      if (pageNum === 0) {
        setPaginatedReviews(selectedReviews.slice(3, 6));
        setPageNum(1);
      } else {
        setPaginatedReviews(selectedReviews.slice(0, 3));
        setPageNum(0);
      }
    }
  };

  return (<>
    {selectedReviews?.length >= 3 && <WrapperSizeable padding={0}>
      <div id="reviewsByLoc">
        <div className="headingContainer">{heading}</div>
        <div className='hiddenStars'>
          <IconRatingsStarFilled size="16" id="lmds-filled" />
          <IconRatingsStarHalf size="16" id="lmds-half-filled" />
          <IconRatingsStarUnfilled size="16" id="lmds-not-filled" />
        </div>
        <div className="reviewsNButtons">
          {displayButtons && <IconButton onClick={() => paginate("left")} data-testid="page-left">
            <IconCaretCircleLeft />
          </IconButton>}
          <div className="reviewsContainer">
            {paginatedReviews?.map(({ authorName, content, rating, entityPath, entityName }) => (
              <div key={authorName} className="individualReview">
                <div className="rating" ><RatingStars rating={rating} size={16} starType="lmds" /></div>
                <div className="authorSection">{authorName},&nbsp;<Link href={entityPath} variant="standalone" className="entityLink" caretPosition="none">{entityName}</Link></div>
                <div>{content}</div>
              </div>))}
          </div>
          {displayButtons && <IconButton onClick={() => paginate("right")} data-testid="page-right">
            <IconCaretCircleRight />
          </IconButton>}
        </div>
        <div className="dotContainer">
          {numPages?.map((page) => (
            <div key={page} className={pageNum === page ? "selectedDot" : "unSelectedDot"} />
          ))}
        </div>
      </div>
      <style global jsx>{`
      @import './global-styles/tokens';
      #reviewsByLoc{
        display: flex;
        flex-direction: column;
        align-items: center;

        .reviewsContainer{
          display: flex;
          justify-content: space-evenly;
          max-width: 72rem;
          width: 100%;
        }
        .rating{
          overflow: hidden;
          height: 1rem;
          margin-bottom: 0.5rem;
          display: flex;
          justify-content: center;
          @media screen and (min-width: $lm-breakpoint-md-min) {
            display: block;
            justify-content: unset;
          }
        }
        .reviewsNButtons{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          @media screen and (min-width: $lm-breakpoint-md-min) {
            max-width: 42rem;
            width: 100%;
          }
          @media screen and (min-width: 1180px) {
            max-width: 72rem;
          }
        }
        .individualReview{
          max-width: 32.25rem;
          @media screen and (min-width: $lm-breakpoint-md-min) {
            margin: 0 1rem;
          }
          @media screen and (min-width: 1180px) {
            max-width: 18.75rem;
            margin: unset;
          }
        }
        .lm-Heading.headingText{
          max-width: unset;
          text-align: center;
          @media screen and (min-width: $lm-breakpoint-lg-min) {
            text-align: left;
            margin-bottom: 3rem;
          }
        }
        .authorSection{
          font-size: 1.25rem;
          margin: 0.5rem 0 1rem 0;
          display: flex;
          flex-flow: column;
          align-items: center;

          @media screen and (min-width: $lm-breakpoint-md-min) {
            flex-flow: row;
            align-items: unset;
          }
        }
        .lm-Link{
          font-weight: 700;
        }
        .hiddenStars{
          display: none;
        }
        .dotContainer{
          display: flex;
          margin: 2rem 0 3rem 0;

          @media screen and (min-width: $lm-breakpoint-md-min) {
            margin: 3rem 0 3rem 0;
          }
        }
        .selectedDot{
          height: 1rem;
          width: 1rem;
          background-color: #C0BFC0;
          border-radius: 1rem;
          margin: 0 0.25rem;
        }
        .unSelectedDot{
          height: 1rem;
          width: 1rem;
          background-color: #E6E6E6;
          border-radius: 1rem;
          margin: 0 0.25rem;
        }
      }
    `}</style>
    </WrapperSizeable>}
  </>);
};

ReviewsByLocation.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  entity: PropTypes.array,
};

export default ReviewsByLocation;