import dynamic from 'next/dynamic';

const Maps = dynamic(() =>
  import(
    /* webpackChunkName: "google-maps" */
    './Maps'
  ),
);

// eslint-disable-next-line import/prefer-default-export
export { Maps };
