import React from 'react';
import PropTypes from 'prop-types';
import { Link, Heading } from '@lmig/lmds-react';
import breakpoints from '@lmig/lmds-tokens/dist/json/core/breakpoint.json';
import LinkEnvironmentalize from './LinkEnvironmentalize';
import { ResourceCard } from './RelatedResources';

const ResourceFeatured = ({
  cardTitle = '',
  cardText,
  cardLinkHref,
  agentImageSrc = '',
  agentImageAlt = '',
  heading,
  blurb,
}) => (
  <div id='resourceWrapper'>
    <div className='textSection'>
      <Heading type="h3" tag="h2" className='headingResource'>{heading}</Heading>
      <div>{blurb}</div>
      <LinkEnvironmentalize domain="COMPARION">
        <Link href='/resources' variant="standalone" className="resourceLink">See all articles</Link>
      </LinkEnvironmentalize>
    </div>
    <ResourceCard title={cardTitle} text={cardText} linkHref={cardLinkHref} agentImageAlt={agentImageAlt} agentImageSrc={agentImageSrc} />
    <style global jsx>{`
      #resourceWrapper{
        display: flex;
        flex-flow: column;
        align-items: center;

        @media screen and (min-width: 72rem) {
          flex-flow: row;
          align-items: unset;
        }
        .textSection{
          display: flex;
          flex-flow: column;
          max-width: 34rem;
          margin: 0 0 2rem 0;
          width: 100%;
          align-items: center;

          @media screen and (min-width: ${breakpoints.lmig.breakpoint.sm.min}) {
            align-items: unset;
          }

          @media screen and (min-width: 72rem) {
            margin: 0 4rem 0 0;
          }
        }
        .lm-Link.resourceLink{
          margin-top: 1rem;
        }
        .lm-Heading.headingResource{
          text-align: center;
          margin: 0 0 0.75rem 0;
          color: #1A1446;

          @media screen and (min-width: ${breakpoints.lmig.breakpoint.sm.min}) {
            text-align: unset;
            margin: 1rem 0 0.75rem 0;
          }
        }
      }
    `}</style>
  </div>
);

ResourceFeatured.propTypes = {
  cardTitle: PropTypes.string,
  cardText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  cardLinkHref: PropTypes.string.isRequired,
  agentImageSrc: PropTypes.string,
  agentImageAlt: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  blurb: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default ResourceFeatured;